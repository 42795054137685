import apiClient from "./apiClient";
import axios from "axios";

export default {
  getAppointmentSearchReport(data) {
    return apiClient.post("list/appointmentSearchReport", data);
  },

  //--------------------------------------------------------------
  //Export Module
  //--------------------------------------------------------------
  downloadReport(url, data, fileName) {
    return axios({
      url: apiClient.defaults.baseURL + "/" + url, //your url
      method: "POST",
      responseType: "blob", // important
      data: data,

      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("token"),
      },
    })
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", fileName); //or any other extension
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      })
      .catch((err) => {
        console.log(err);
      });
  },
  //--------------------------------------------------------------


  //--------------------------------------------------------
  //Demographics Reports
  //--------------------------------------------------------
  getPatientReports(data) {
    return apiClient.post("reports/list", data);
  },
  downloadPatientReport(data) {
    return apiClient.post("reports/download", data);
  },
  uploadPatientReport(data) {
    return apiClient.post("reports/upload", data);
  },  
  deletePatientReport(data) {
    return apiClient.post("reports/delete", data);
  },  
  //--------------------------------------------------------------


  //--------------------------------------------------------------
  //Enrollment Report
  //--------------------------------------------------------------
  getEnrollmentSearchReport(data) {
    return apiClient.post("reports/enrollment-list", data);
  },
  //--------------------------------------------------------------










  // getAppointmentSearchExportToExcel(data) {
  //   this.downloadReport(
  //     "exportDocuments/appointmentSearchReportExportToExcel",
  //     data,
  //     "Patient-Details.xlsx"
  //   );
  // },
  // getAppointmentSearchExportToCSV(data) {
  //   this.downloadReport(
  //     "exportDocuments/appointmentSearchReportExportToCsv",
  //     data,
  //     "Patient-Details.csv"
  //   );
  // },
  // getAppointmentSearchExportToLabel(data) {
  //   this.downloadReport(
  //     "exportDocuments/appointmentSearchReportExportToLabel",
  //     data,
  //     "Patient-Details-Label.xlsx"
  //   );
  // },
  // getAppointmentSearchExportToPDF(data) {
  //   this.downloadReport(
  //     "exportDocuments/appointmentSearchReportExportToPdf",
  //     data,
  //     "Patient-Details.pdf"
  //   );
  // },

  //--------------------------------------------------------------
};
