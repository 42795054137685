import notifications from "@/components/common/notifications";
import listAPI from "@/api/listAPI.js";
import reportsAPI from "@/api/reportsAPI.js";
import store from "@/store/index";
import router from "@/router/index.js";
import Header from "@/components/Header.vue";
//import axios from "axios";
import permissions from "@/api/permissions.js";

export default {
  name: "report-enrollment",
  components: {
    Header,
    notifications,
  },
  data: () => ({
    options: { page: 1 },
    totalRecords: 0,
    tblLoading: false,
    vrEnrollments: [],
    vrTurnaround: [],
    vrDeviceType: null,
    vrEnrollmentType: null,
    clearable: true,

    //Diagnosis-----------
    vrDiagnosis: [],
    //model_diagnosis: null,
    isLoadingDiagnosis: false,
    searchDiagnosis: null,
    vrStatus:[{id:'B',value:'Both'},{id:'C',value:'Completed'},{id:'N',value:'Not Completed'}],

    headers: [
      // { sortable: false, text: "#", value: "id" },
      // {
      //   sortable: false,
      //   text: "PATIENT ID",
      //   value: "patient_code",
      //   width: "10%",
      // },
      {
        sortable: false,
        text: "DATE",
        value: "create_date",
        width: "10%",
      },
      {
        sortable: false,
        text: "PATIENT NAME",
        value: "patient_name",
        width: "20%",
      },
      {
        sortable: false,
        text: "ENROLLMENT TYPE",
        value: "enrollment_type",
        width: "12%",
      },
      {
        sortable: false,
        text: "RECEIVED BY CLIENT",
        value: "auto_enroll_receive_dt",
        width: "12%",
      },
      {
        sortable: false,
        text: "COMPLETED ENROLLMENT",
        value: "auto_enroll_complete_dt",
        width: "14%",
      },
      {
        sortable: false,
        text: "ENROLLMENT TECHNICIAN",
        value: "entrollment_technician",
        width: "20%",
      },
      {
        sortable: false,
        text: "TURNAROUND TIME",
        value: "enrollment_turnaround_time",
        width: "12%",
      },
    ],
    formData: {},

    exportLoadingLabel: false,
    exportLoadingExcel: false,
    exportLoadingCSV: false,
    exportLoadingPDF: false,

    disableExport: false,

    isPostback: false,

    reportsURL: {
      //label: "exportDocuments/appointmentSearchReportExportToLabel",
      csv: "exportDocuments/ExportToCsvReport",
      excel: "exportDocuments/ExportToExcelReport",
      pdf: "exportDocuments/ExportToPdfReport",
    },

    //---------------------------------------------------
    //Used for permission handling
    //---------------------------------------------------
    permissions: {},
    //---------------------------------------------------
  }), //End Data

  watch: {
    options: {
      handler() {
        this.loadEnrollmentReport();
      },
      deep: true,
    },
    searchDiagnosis(val) {
      // Items have already been requested
      if (this.isLoadingDiagnosis) return; //Return

      this.isLoadingDiagnosis = true; //initiate loading

      if (val !== null) {
        listAPI
          .getDiagnosisList({ SearchString: val })
          .then((response) => {
            if (response.data.results !== null)
              this.vrDiagnosis = response.data.results.Diagnosis;
          })
          .catch((err) => {
            console.log(err);
          })
          .finally(() => {
            this.isLoadingDiagnosis = false;
          });
      }

      this.isLoadingDiagnosis = false;
    },
  },
  methods: {
    selectPatient(item) {
      //Set the patient Id for other screens/flow
      store.state.patientId = item.patient_id;

      store.state.redirectURL = "report-enrollment";

      router.push("patient");
    },
    loadEnrollmentReport: function (e) {
      if (!this.isPostback) {
        this.vrEnrollments = [];
        this.totalRecords = 0;
        this.disableExport = true;

        this.isPostback = true;
        return false;
      }

      this.tblLoading = true;
      this.formData.pagination = this.options;
      this.formData.exportType = null;

      reportsAPI
        .getEnrollmentSearchReport(this.formData)
        .then((response) => {
          if (response.data.results !== null) {
            this.vrEnrollments = response.data.results.Patients;
            this.totalRecords = response.data.results.PatientCount.total_count;
            if (this.totalRecords > 0) {
              this.disableExport = false;
            } else {
              this.disableExport = true;
            }
          }
        })
        .catch((err) => {
          console.log(err);
          this.disableExport = true;
        })
        .finally(() => {
          this.tblLoading = false;
        });

      if (!!e == true) e.preventDefault();
    },

    exportLabel() {
      this.formData.exportType = "LABEL";
      this.exportLoadingLabel = true;

      reportsAPI
        .downloadReport(
          //"exportDocuments/exportToLabel",
          this.reportsURL.label,
          this.formData,
          "Enrollment-Report.xlsx",
          this.exportLoadingLabel
        )
        .then(() => {
          this.exportLoadingLabel = false;
        });
    },
    exportCSV() {
      this.formData.exportType = "CSV";
      this.exportLoadingCSV = true;

      reportsAPI
        .downloadReport(
          //"exportDocuments/exportToCsv",
          this.reportsURL.csv,
          this.formData,
          "Enrollment-Report.csv"
        )
        .then(() => {
          this.exportLoadingCSV = false;
        });
    },
    exportExcel() {
      this.formData.exportType = "EXCEL";
      this.exportLoadingExcel = true;
      reportsAPI
        .downloadReport(
          //"exportDocuments/exportToExcel",
          this.reportsURL.excel,
          this.formData,
          "Enrollment-Report.xlsx"
        )
        .then(() => {
          this.exportLoadingExcel = false;
        });
    },
    exportPDF() {
      this.formData.exportType = "PDF";
      this.exportLoadingPDF = true;

      reportsAPI
        .downloadReport(
          //"exportDocuments/exportToPdf",
          this.reportsURL.pdf,
          this.formData,
          "Enrollment-Report.pdf"
        )
        .then(() => {
          this.exportLoadingPDF = false;
        });
    },

    clearAllFields() {
      this.formData = {};

      this.options.page = 1;
      this.isPostback = false;
      this.loadEnrollmentReport();
    },
  },

  beforeMount() {
    listAPI
      .getDeviceType()
      .then((response) => {
        if (response.data.results !== null)
          this.vrDeviceType = response.data.results.Device_Type;
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {});

    listAPI
      .getLookup("TURNAROUND_TIME")
      .then((response) => {
        if (response.data.results !== null)
          this.vrTurnaround = response.data.results.Lookup;
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {});

    listAPI
      .getLookup("ENROLLMENT_TYPE")
      .then((response) => {
        if (response.data.results !== null)
          this.vrEnrollmentType = response.data.results.Lookup;
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {});
  },
  computed: {
    vrPatientID() {
      return store.state.patientId;
    },
    vrNotify() {
      return store.state.notify;
    },
  },
  mounted() {},
  created() {
    //Get permissions for logged in user
    this.permissions = permissions.getPermissions("search");
  },
};
